@import "global.css";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap");

.onBoarding-container {
    position: relative;
    background-color: #F0F0F0;
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 2%;
}
.form-wizard-area{
    position: relative;
    background-color: white;
    height: auto;
    width:100%;
    min-width: 300px;
    max-width: 800px!important;
    border-radius: 10px;
}
.form-wizard{
    position: relative;
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between!important;
    align-items: center!important;
}

.form-wizard-area h1 {
    color: var(--blue)!important;
    font-weight: 700;
    font-size: 18px;
}
.form-wizard-area h2 {
    color: var(--blue)!important;
    font-weight: 400;
    font-size: 16px;
    padding: 30px;
}
.form-wizard-header  {
    height: 60px;
    border-bottom: 1px solid #F0F0F0;
    width: 100%;
}
.form-wizard-choose{
    border-bottom: 1px solid #F0F0F0;
    color: var(--orange);
    width: 100%;
}
.options-area {
    width: 100%;
    padding:0px 5px ;
    
}
.options-area button{
    background-color: var(--white);
    padding: 15px 0px;
    border-radius: 50px;
    margin:10px 0px;
    color: var(--blue); 
    width: 100%;
    border: #F0F0F0 1px solid;
    
}
.options-area button:hover{
    background-color: var(--orange);
    padding: 15px 10px;
    border-radius: 50px;
    margin:10px 0px;
    color: white;
    width: 100%;
}
.options-area .active{
    background-color: var(--orange);
    padding: 15px 10px;
    border-radius: 50px;
    margin:10px 0px;
    color: white!important;
    width: 100%;
}
.form-wizard-footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #F0F0F0;
    margin-top: 50px;

}
.form-wizard-footer button{
    
    margin: 5px;

}