@import "global.css";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap");

.footer-container{
    background-color: white;
}
.footer-list-group .list-group-item{
    border: none;
}
.footer-list-group-bottom .list-group-item{
    color: #999D9E;
    /* Footnote Regular Desktop */
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
}
.footer-social-media img{
    padding: 10px;
}
.footoer-bottom-area{
    border-top: 1px solid #F0F0F0;
    padding-top:20px ;
}