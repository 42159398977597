@import "../global.css";


.icon-card-container{
    background-color: white;
    color: var(--blue)!important;
    border-radius: 20px;
    padding: 10px;
}
.icon-card-hovered{
    background-color:  #FFFFFF;
    box-shadow: 0px 0px 50px 0px rgba(153, 157, 158, 0.25);
}
.icon-card-img{
    width: 60px;
    height: 60px;
    padding: 10px;
    border-radius: 10px;
}
/* .icon-card-img-hovered{
    background: var(--orange);
} */

.icon-card-title{
    color: var(--blue)!important;
    font-size: 22px;
    font-weight:400 ;
}
.icon-card-desc{
    color: var(--blue)!important;
    font-size: 16px;
}