@import "../global.css";



.btn-theme {
  max-width: 150px;
  width: 200px;
  min-height: 50px;
  border: none;
  border-radius: 60px;
  transition: background-color 0.3s ease;
}

.btn-theme-2 {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  min-height: 50px;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}
.btn-theme-2.dont{
  color: #FFF;
  box-shadow: 0px 0px 50px 0px rgba(251, 133, 0, 0.25);

text-align: center;
/* Footnote Regular Desktop */
font-family: Plus Jakarta Sans;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 100%; 
}

.btn-theme-small {
  height: 55px;
  border: none;
  border-radius: 60px;
  transition: background-color 0.3s ease;
}

.btn-theme-icon-area {
  position: relative;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  padding-right: -20px !important;
}

.btn-theme-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px !important;
  height: 20px !important;
}





/* DARK AREA */
.btn-theme-dark {
  background-color: #023047;
  color: white;
}

.btn-theme-dark-hover {
  transition: background-color 0.3s ease;
  background-color: var(--orange);
  color: white;
}

.btn-theme-icon-area-dark {
  background-color: #00293E;
}

.btn-theme-icon-area-dark-hover {
  background-color: #E07700;
}

/* orange AREA */
.btn-theme-orange {
  background-color: var(--orange);
  color: white;
}

.btn-theme-orange-hover {
  opacity: 0.85;
}

.btn-theme-icon-area-orange {
  background-color: #E07700;
}

.btn-theme-icon-area-orange-hover {}

/* orange AREA */
.btn-theme-light {
  background-color: transparent;
  color: var(--blue);
  border: var(--blue) solid 1px !important;
}

.btn-theme-light-hover {
  opacity: 0.85;
}

.btn-theme-icon-area-light {
  background-color: var(--blue);
}

.btn-theme-icon-area-light-hover {}





.theme-link {
  color: var(--blue);
  cursor: pointer;
  text-decoration: none;
}

.theme-link:hover {
  color: var(--orange) !important;
}



@media screen and (max-width:1000px) {
  .btn-theme-dark {
    width: 50px;
    height: 50px;
  }

  .btn-theme-orange {
    width: 50px;
    height: 50px;

  }
  .btn-theme-orange.dont {
    width: 100%;
  }
  .btn-theme-light {
    width: 50px !important;
    height: 50px !important;
    background-color: var(--blue);
  }
}