@import 'global.css';
/* Scroll çubuğu rengini özelleştirme */
::-webkit-scrollbar {
  width: 3px; /* Scroll çubuğu genişliği */
}

/* Scroll çubuğu başlığının rengini ayarlama */
::-webkit-scrollbar-thumb {
  background-color: var(--orange); /* Scroll çubuğu rengi */
}

body{
  scrollbar-color: #336699 #f0f0f0!important; 
  overflow-x: hidden;
  overflow-y: hidden;
}
.demands-container h1{
  color: var(--blue)!important;
  font-size: 16px!important;
}
.empty{
  background-color: #e2e2e2!important;
  border-radius:10px ;
}
.demands-container {
  height: 90vh!important;
  position: relative;
  overflow-y: hidden!important;
}

.left-section {
  background-color: #f0f0f0;
  transition: all 0.3s linear;
}
.left-section-header h1 {
  color: #999D9E!important;
  padding: 0px 5px ;
  cursor: pointer;
}
.left-section-header .active {
  color: var(--blue)!important;
}
.left-section-header span {
  font-size: 20px;
  color: var(--orange);
}
.left-section-list {
  background-color: #f0f0f0;
  height: 87vh;
  overflow-y: scroll;
}
.middle-section {
  
  background-color: #f0f0f0;

}
.middle-section-list {
  overflow-y: scroll;
  background-color: #f0f0f0;
  height: 87vh;
}
.right-section {
  background-color: #f0f0f0;
}
.right-section-list {
  height: 87vh;
  overflow-y: scroll;
}
@media (max-width:765px) {
  .demands-container{
    height: calc(100vh - 90px) !important;
  }
  .left-section-list {
    padding-bottom: 50px !important;
  }
}
