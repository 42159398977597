@import "../global.css";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap");

.search-container {
    background-color: transparent;
    max-width: 700px!important;
    border-radius: 40px;
    padding: 5px;
    height: auto;
   
  }

  .search-categories {
    display: flex !important;
    flex-direction: row;
    width: 100%;
    background-color: rgba(2, 48, 71, 0.05);
    border-radius: 40px;
    padding: 10px;
    margin: 0px 5px!important ;
  }

  .search-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
  }

  .active-search-item {
    background-color: #00293e;
  }

  .search-item-name {
    color: #d2d2d2;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
  }

  .search-item-name-active {
    color: #fff;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    width: 100%;
  }

  .search-item-name-next {
    color: #fb8500;
    /* Footnote Bold Mobile */
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 12px;
    font-weight: 700;
    width: 100%;
  }
  .search-input {
    background-color: #fff;
    width: 100%;
    border: none;
    height: 100%;
  }
  .search-form-container {
    margin: 20px 5px!important ;
    background-color: #ffffff;
    border-radius: 70px;
    border: #F9F9F9 2px solid;
  }
  .search-form-area {
    height: 60px;
  }

  /* Desktop */
@media screen and (max-width: 980px) {
  .search-container {
    background-color: transparent;
    padding: 0px;
   
  }
    .search-form-container {
      margin: 15px 0px transparent;
     
    }
    .search-form-area {
      background-color: #ffffff;
      height:50px ;
      border-radius: 70px;
    }
  }

  
  