@import "../global.css";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap");

.theme-card-container {
    width: 100%;
    min-height: 350px;
    
    
  
}
.theme-card-area {
    width: 100%!important;
    
}

.theme-card-image{
    position: relative;
    height: 300px;
    border-radius:20px;
    width: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    transition: background-size 1s ease;
    
}
.theme-card-area .hovered {
    background-size:150% 150%;
    
}
.theme-card-title-area{
    background-color: var(--white);
    position: absolute;
    width: 95%;
    height: 35%;
    bottom: 0;
    border-radius:20px;
    opacity: 0;
    transition: opacity 0.5s ease;
    margin-bottom:-50px;
    cursor: pointer;
    box-shadow: 0px 0px 50px 0px rgba(153, 157, 158, 0.9);
    
}

.theme-card-title{
    position: absolute;
    bottom: 0;
    color: black!important;
    transform: translate(0px, 50px);
    transition: transform 0.5s ease;
    z-index: 1;
}
.title-hovered{
   
    transform: translate(10px, 0px);
}
 .title-area-hovered{
    opacity: 1;
} 
.theme-card-link{
    position: absolute;
    bottom: 0;
    cursor: pointer;
    margin: 10px;
    color: var(--orange);
    text-decoration: none;
}
.theme-card-info{
    position: absolute;
    right: 0;
    margin:18px;
    color: var(--blue);
    font-size: 12px;
}