@import '../global.css';
.demand-details-container {
    
}

.demand-details-area {
    width: 100%;
    height: auto;
    min-height: 150px;
    margin: 15px 0px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 30px;
    cursor: pointer;
}
.demand-details-container:hover {
    opacity: 0.8;
}
.demand-status{
    border-radius:50px ;
    width: 10px;
    height: 10px;
    background-color: var(--orange);
    margin: 5px 10px 0px 0px;
}

.demand-details-container h1 {
    color: var(--blue)!important;
    font-size: 16px!important;
    font-weight: 400;
    white-space: nowrap;
    margin-bottom: 5px!important;
    
}
.demand-details-container h2 {
    color: var(--orange)!important;
    font-size: 14px!important;
    font-weight: 400!important;
    margin-bottom: 5px!important;
}
.demand-details-container p {
    color: var(--darkGray)!important;
    font-size: 14px!important;
    text-align: left;
    margin-bottom: 5px!important;
}

.demand-details-data-item{
    width: 100%;
    padding: 10px;
    border-radius:20px;
    margin: 10px 0px;
    background-color: white;
    text-align: center;
    font-size: 14px;
    color: var(--blue);
    font-family: Plus Jakarta Sans;
}
.phone-icon{
    width: 30px;
    margin:3px;
}
.date-icon{
    width: 15px;
    margin:0px 3px;
}
.demand-details-desc{
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    margin:10px 0px;
}
.demand-details-customer{
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    margin:20px 0px;
}
.profile-img{
    width: 40px;
    height: 40px;
    margin: 0px 5px;
}
