@import '../global.css';

.offer-card-container {
    width: 100%;
    height: 200px;
    margin: 15px 0px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 20px;
    cursor: pointer;
}
.offer-card-container:hover {
    opacity: 0.85;
    border: var(--darkGray) solid 1px;
}
.clicked {
    opacity: 0.85;
    border: var(--orange) solid 1px!important;
}

.offer-status{
    border-radius:50px ;
    width: 10px;
    height: 10px;
    background-color: var(--orange);
    margin: 5px 10px 0px 0px;
}

.offer-card-container h1 {
    color: var(--blue)!important;
    font-size: 16px!important;
    font-weight: 400;
    white-space: nowrap;
    margin-bottom: 0px!important;
    
}
.offer-card-container h2 {
    color: var(--orange)!important;
    font-size: 14px!important;
    font-weight: 400;
    margin-bottom: 0px!important;
}
.offer-card-container h3 {
    color: var(--white)!important;
    font-size: 20px!important;
    font-weight: 400;
    margin-bottom: 0px!important;
}
.offer-card-container p {
    color: var(--darkGray)!important;
    font-size: 14px!important;
    text-align: left;
    margin-bottom: 0px!important;
}
 .list-group-offer{
    font-family: Plus Jakarta Sans;
    border:none!important;
    font-size: 10px;
    background-color: var(--gray)!important;
    max-width: 120px!important;
    margin: 3px 3px;
    border-radius: 20px!important;
    white-space: nowrap;
    text-align: center;
}
.offer-card-container h2{
    font-weight: 500!important;
}

.offer-pay{
    font-family: Plus Jakarta Sans;
    background-color: var(--gray);
    padding:  5px 10px;
    border-radius: 5px;
    color: var(--dark);
    font-size: 12px;

}
.offer-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 5px 0px;
    font-size: 12px;
    font-family: Plus Jakarta Sans;
    
}
.offer-card-row{
    background-color: var(--blue);
    padding: 20px;
    border-radius: 10px;
    margin: 0px!important;
}
.offer-card-title{
    font-size: 16px!important;
    font-weight: 500;
    color: #FFCF98!important;
}
.nowrap{
    white-space: nowrap;
}
.offer-item{
    color: var(--blue);
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
}
