@import 'global.css';
/* DARK THEME */
.theme-nav-link-dark .nav-link{
    color: var(--white) ;
  
  }
  .theme-nav-link-dark .nav-link:hover{
    color: var(--orange);
  }

  .theme-nav-link-dark .nav-link:active{
    color: var(--orange)!important;
  }

  /* LIGHT THEME */
  .theme-nav-link-light .nav-link{
    color: var(--blue) ;
  
  }
  .theme-nav-link-light .nav-link:hover{
    color: var(--orange);
  }

  .theme-nav-link-light .nav-link:active{
    color: var(--orange)!important;
  }

  .active{
    color: var(--orange)!important;
  }
  
  
  @media screen and  (max-width: 580px) {
    .theme-nav-link-dark .nav-link{
      color: var(--blue)!important;
    
    }
    .navbar-toggler{
      color: #F9F9F9!important;
      background-color: #F9F9F9!important;
      border: #F9F9F9!important;
    }
  }