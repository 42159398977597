@import "global.css";


*:focus {
  outline: none;
}
.background-dark {
  background-color: var(--blue);
}
.background-light {
  background-color: var(--gray);
  /* height: 100vh !important; */
  min-height: 100vh !important;
  padding-bottom: 50px !important;
}
.feed-container {

}

.feed {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
}
.home-paragraph {
  max-width: 60%;
}
.home-paragraph p{
  font-size: 18px;
}
.feed-middle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
  background-color: #ffffff;
}
.custom-placeholder::placeholder {
  padding-left: 30px;
}
.btn-primary {
  background-color: var(--blue) !important;
  width: 160% !important;
}
.cities-container {
  background: linear-gradient(to top, white 50%, var(--blue) 50%);
  
}
.cities-p {
  color: var(--blue) !important;
  font-size: 32px;
  font-weight: 600;
}
.cities-card-area {
  padding: 30px;
  margin-top: -1400px;
  background-color: #fff;
  border-radius: 20px;
}



/* About Us Home */
.about-container {
  padding: 5%;
  background: white;
}

.about-area {
  background: var(--white);
  border-radius: 20px;
}
.about-img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20px;
}

.section-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  color: var(--orange)!important;
  text-align: left;
}

.section-subtitle {
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  color: var(--blue)!important;
  text-align: left;
}
.section-span {
  color: var(--orange);
}

.section-text {
  font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 1.5;
color: var(--blue)!important;
text-align: left;
}

.what-do-container{
  background-color: white;
  padding: 0px 0px 120px  0px;
}

.demand-container{
  background-color: var(--blue);
}

.about-card{
  padding: 10px;
  margin: 10px !important;
  border-radius: 20px;
  background-color: white;
}
.about-img{
  width: 125px !important;
  object-fit: cover;
  height: 125px;
  border-radius: 20px;
  box-shadow: 0px 0px 50px 0px rgba(153, 157, 158, 0.25);
}
.about-p{
  color: #1E1E1E !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important;
}
.about-gp{
  color: #999D9E !important;
  font-family: Plus Jakarta Sans !important; 
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important; 
}
.about-search{
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  background: #FFF;
  min-height: 40px;
}
.about-p-gray{
  color: var(--darkGray) !important;
}
.about-p-lightgray{
  color: var(--lightGray) !important;
}
.language-card{
  background-color: #F9F9F9;
  border-radius: 20px;
}
.photo-card{
  border-radius: 20px;
}
progress {
  /* style rules */
  margin-bottom: 1rem;
  border-radius: 7px; 
  width: 100%;
  height: 5px;
}
progress::-webkit-progress-bar {
  /* style rules */
  background-color: var(--otherGray);
  border-radius: 7px;
}
progress::-webkit-progress-value {
  /* style rules */
  background-color: var(--orange);
  border-radius: 7px;
}
progress::-moz-progress-bar {
  /* style rules */
}
.comment-star{
  width: 10px;
}
.comment-star-med{
  width: 15px;
}
.profile-menu{
  color: #1E1E1E !important;
  text-align: center !important;
  font-family: "Plus Jakarta Sans",sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important; 
  margin: 0 !important;
  padding: 2px 2px 2px 10px;
  cursor:pointer;
  }
.profile-menu.selected{
  border-radius: 10px;
  background: rgba(240,240,240,1);
}
.personal-image{
  width: 100%;
  border-radius: 20px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
.edit-image-btn{
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 25px;
}
.person-input-label{
  color: #1E1E1E !important;
  font-family: "Plus Jakarta Sans",sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important; 
}
.checkbox-img{
  width: 20px !important;
}
.address-textarea{
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  background: #FFF;
  width: 100%;
  min-height: 150px;
}
.PhoneInput{
    border-radius: 10px;
    border: 1px solid #F0F0F0;
    background: #FFF;
    min-height: 40px;
}
.PhoneInputCountry{
  padding-left: 5px;
}
.PhoneInputInput{
  border: 0 !important;
}
.credit{
  color:var(--orange) !important;
  font-family: "Plus Jakarta Sans";
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}
.card-div{
  border-radius: 10px;
  height: 30px;
  border: 1px solid #C3C3C3;
}
.card-div.selected{
  background: #023047;
}
.gray-area{
  background-color: var(--gray);
  padding: 10px;
  border-radius: 10px;
}
.account-table{
  width: 100%;
  border-bottom: 1px solid #E4E4E4;
  margin-top: 20px;
}
.account-table th{
  padding: 10px;
  text-align: center;
  border: 1px solid #E4E4E4;
  border-top:0
}
.account-table td{
  padding: 10px;
  text-align: center;
  border: 1px solid #E4E4E4;
}
.wallet h5{
  color: #023047;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%
}
.auth-card{
  border-radius: 10px;
  width: 575px;
  min-height: 560px;
  background: #FFF;
  margin: 20px;
}
.detail-div
{
  background-color: var(--gray);
  border-radius: 10px;
  width: fit-content;
  padding-inline: 15px;
}
.cursor{
  cursor: pointer !important;
}
.blue{
  color:var(--blue) !important;
}
.orange {
  color:var(--orange) !important;
}
.auth-container {
  background: var(--gray) !important;
  min-height: 100vh;
}
.auth-input{
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  background: #FFF;
  width: 240px;
  height: 40px;
  flex-shrink: 0;
  padding-left: 10px;
}
.auth-input:focus{
  border:1px solid var(--orange) !important
}
.auth-input-label{
  color: #1E1E1E;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 5px;
}
.error{
  color: #F00 !important;
  text-align: center !important;
  font-family: "Plus Jakarta Sans" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
}
.show{
  position: absolute;
  right: 10px;
  top: 50%;
}
.swiper-wrapper .swiper-slide:first-child{
  width: 100px !important;
}
/* Mobile */
@media screen and (max-width: 700px) {
  .feed {
    padding: 0px;
  }

  .home-paragraph {
    max-width: 100%;
    padding: 20px;
  }

  .feed-middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6rem;
    min-height: 100vh;
    background-color: #ffffff;
  }
  .cities-container {
    margin-top: 50px;
  }

 

}
