@import '../global.css';

.demand-offer-container{
    
}
.demand-offer-area{
    border-radius:20px;
    background-color: white;
    width: 100%;
    min-height: 300px;
    padding: 20px;
}

