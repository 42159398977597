@import '../global.css';

.demand-messages-container{
    
}
.demand-messages-area{
    
    width: 100%;
    max-height: 800px;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    
}
.demand-messages-item-area{
    overflow-y: scroll ;
}
.demand-messages-item{
    border-radius:20px;
    margin-top: 20px;
    padding: 20px;
}
.demand-messages-area p{
    font-size: 12px;
    text-align: left;
}
.demand-messages-area .user{
    background-color: var(--blue);
 
}
.demand-messages-area .other-user{
    background-color: var(--gray);
}
.other-user p{
    color: var(--blue)!important;
}


.demand-messages-send-area{
    background-color: var(--gray);
    border-radius: 20px;
    padding: 5px;
    margin-top:30px
}
.messages-textarea{
 margin-right: 10px;
}