@import '../global.css';

.demand-card-container {
    width: 100%;
    height: 200px;
    margin: 15px 0px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 20px;
    cursor: pointer;
}
.demand-card-container:hover {
    opacity: 0.85;
    border: var(--darkGray) solid 1px;
}
.clicked {
    opacity: 0.85;
    border: var(--orange) solid 1px!important;
}

.demand-status{
    border-radius:50px ;
    width: 10px;
    height: 10px;
    background-color: var(--orange);
    margin: 5px 10px 0px 0px;
}

.demand-card-container h1 {
    color: var(--blue)!important;
    font-size: 16px!important;
    font-weight: 400;
    white-space: nowrap;
    margin-bottom: 0px!important;
    
}
.demand-card-container h2 {
    color: var(--orange)!important;
    font-size: 14px!important;
    font-weight: 400;
    margin-bottom: 0px!important;
}
.demand-card-container p {
    color: var(--darkGray)!important;
    font-size: 14px!important;
    text-align: left;
    margin-bottom: 0px!important;
}
 .list-group-demand{
    font-family: Plus Jakarta Sans;
    border:none!important;
    font-size: 10px;
    background-color: var(--gray)!important;
    max-width: 120px!important;
    margin: 3px 3px;
    border-radius: 20px!important;
    white-space: nowrap;
    text-align: center;
}
.demand-card-container h2{
    font-weight: 500!important;
}
