@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans");

:root {
  --blue: #023047;
  --lightBlue: #219EBC;
  --extraLightBlue: #8ECAE6;
  --orange: #FB8500;
  --lightOrange: #FFB703;
  --white: #F9F9F9;
  --gray: #F0F0F0;
  --lightGray:rgba(153, 157, 158, 1);
  --darkGray: #545557;
  --otherGray:#D9D9D9;
  --max-width: 1100px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  overflow-y: scroll !important;
}


h1 {
  font-family: Plus Jakarta Sans !important;
  color: var(--white) !important;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 60px;
}

h2 {
  font-family: Plus Jakarta Sans !important;
  color: var(--white) !important;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 37px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 44px;
}

h3 {
  font-family: Plus Jakarta Sans !important;
  color: var(--blue) !important;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 29px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 44px;
}

p {
  font-family: Plus Jakarta Sans !important;
  color: var(--white) !important;
  text-align: center;
  font-family: " Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1.5;
}

label {
  font-family: Plus Jakarta Sans !important;
  color: var(--blue) !important;
  text-align: start;
  font-size: 14px;
  font-weight: 700;
}

.theme-input {
  border-radius: 10px 0px 0px 10px !important;
  height: 50px;
  
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2);
  border:1px solid var(--gray)!important;
  border-right: none !important;

}

.theme-input:hover {
  border-color: var(--orange) !important;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2) !important;

}

.theme-input:focus {
  border-color: var(--orange) !important;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2) !important;

}

.theme-textarea {
  min-height: 100px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2);
  border:1px solid var(--gray)!important;
}

.theme-textarea::placeholder {
  color: #c2c1c1 !important;
}

.theme-textarea:hover {
  border-color: var(--orange) !important;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2) !important;
}

.theme-textarea:focus {
  border-color: var(--orange) !important;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2) !important;
}

.theme-select {
  border-radius: 0px 10px 10px 0px !important;

  height: 50px;
  width: 100px !important;
  box-shadow: 50px 0px 50px 0px rgba(183, 183, 183, 0.2);
  border:1px solid var(--gray)!important;
  border-left: none !important;
}

.theme-select:hover {
  border-color: var(--orange) !important;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2) !important;
}

.theme-select:focus {
  border-color: var(--orange) !important;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2) !important;
}

.theme-file {
  border-radius: 10px !important;
}

.theme-file:hover {
  border-color: var(--orange) !important;
  box-shadow: var(--orange) 0px 0px 0px 0.1px !important;
}

.theme-file:focus {
  border-color: var(--orange) !important;
  box-shadow: var(--orange) 0px 0px 0px 0.1px !important;
}

input[type="file"] {
  color: #DFDFDF;
  cursor: pointer;
}


.custom-file-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2);
  height: 60px;
  border-radius: 10px;
  border:1px solid var(--gray);
  cursor: pointer;
}
.custom-file-label:hover{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.2);
  height: 60px;
  border-radius: 10px;
  border:1px solid var(--orange);
  cursor: pointer;
}

.custom-file-label p {
  color: #b6b6b6 !important;
  margin: 0 !important;
}

.custom-file-label img {
  padding: 10px 13px;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0px 0px 50px 0px rgba(183, 183, 183, 0.5);
}


.text-orange {
  color: var(--orange) !important;
}

.text-blue {
  color: var(--blue) !important;
}

/* Desktop */
@media screen and (max-width: 980px) {
  h1 {
    color: #fff;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    max-width: 100%;
  }
}

/* Mobile */
@media screen and (max-width: 700px) {

  h1 {
    color: #ffffff;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 200;
    line-height: 30px;
    width: 100%;
  }

  p {
    color: #ffffff;
    text-align: center;
    font-family: " Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  h2 {
    color: #ffffff;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
  }


}